import "./GuestsAmenities.scss"

export const GuestsAmenities = () => {

  window.addEventListener('scroll', function() {
    var scrollPosition = window.pageYOffset;
    var parallaxImg = document.querySelector('.parallax-img');

    // Dostosuj współczynnik do prędkości przewijania, np. 0.5
    var factor = 0.2;
    if(parallaxImg){
      parallaxImg.style.transform = `translate(-50%, calc(-50% + ${scrollPosition * factor}px))`;
    }
    
  })

  return(
    <div className="container guests-amenities-module animate__animated animate__lightSpeedInRight">
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-6">
          <div className="img-box">
            <img className='parallax-img' src="8.jpg"/>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 list">
          <h4>Do dyspozycji gości:</h4>
          <ul>
            <li>Wyznaczone miejsce na ognisko i grill.</li>
            <li>Plac zabaw i piaskownica dla dzieci.</li>
            <li>Trampolina i stół do ping-ponga.</li>
            <li>Boisko do siatkówki.</li>
            <li>Dostępny sprzęt pływający, w tym dwa kajaki, łódka z wiosłami i rower wodny.</li>
            <li>Dodatkowo: rowery i komplet kapoków.</li>
            <li>Plaża oddalona o około 100 metrów z pomostem i możliwością wypożyczenia sprzętu wodnego.</li>
            <li>Duży las w najbliższej okolicy.</li>
          </ul>
        </div>
      </div>
    </div>
  )
}