import { slide as Menu } from 'react-burger-menu'
import './NavbarMobileView.scss'
import { NavLink } from 'react-router-dom';

export const NavbarMobileView = ()=> {

  const showSettings = (event) => {
    event.preventDefault();
  }

  
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <div className='mobile-module' >
        <Menu >
          <NavLink to={'/'}>
            <h5>Strona główna</h5>
          </NavLink>
          <NavLink to={'/dom'}>
            <h5>Opis domu</h5>
          </NavLink>
          <NavLink to={'/galeria'}>
            <h5>Galeria</h5>
          </NavLink>
          <NavLink to={'/cennik'}> 
            <h5>Cennik</h5>
          </NavLink> 
          <NavLink to ={'/atrakcje'}>
            <h5>Atrakcje</h5>
          </NavLink>
          <NavLink to={'/kontakt'}>
            <h5>Kontakt</h5>
          </NavLink>
          <NavLink to={'/regulamin'}>
            <h5>Regulamin</h5>
          </NavLink>
        </Menu>
      </div>
    );
  
}