import './Gallery.scss'
import { Container, Row, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';

export const Gallery = ({number}) =>{
  console.log(number)
  return (
    <Container className='gallery-module'>
      <Row xs={1} md={2} lg={2} className="g-4">
        {number.map((number)=>(
          <Col key={number} className='img-box' >
            <Image src={`${number}.jpg`} className="img-fluid"  alt={`Obraz ${number}`} thumbnail />
          </Col>
        ))}
      </Row>
    </Container>
  )
}