import './Main.scss'
import { Gallery } from '../../Modules/Gallery/Galerry'
import { GuestsAmenities } from './GuestsAmenities/GuestsAmenities'

export const Main = () =>{
  const numbers =[1,2,3,4]

  return(
    <div className='container main-module'>
      <div className='row'>
        <div className='col-12 col-sm-6 col-md-12 col-lg-7 animate__animated animate__rollIn'>
          <p>Jeśli marzysz o spokojnym i urokliwym miejscu, położonym wśród przyrody z dala od zgiełku miasta,nasza oferta jest właśnie
             dla Ciebie. Oferujemy uroczy domek letniskowy, położony na pograniczu Mazur i Podlasia, nad Jeziorem Rajgrodzkim.</p>
          <p>Domek znajduje się w Czarnej Wsi, zaledwie 6 kilometrów od Rajgrodu. W okolicy znajdują się również inne atrakcyjne miejsca</p>
          <p>To położenie sprzyja beztroskiemu wypoczynkowi, zwłaszcza dla miłośników pieszych i rowerowych wędrówek oraz wędkarzy. Dom usytuowany
             jest w bezpiecznej, spokojnej części wsi, w której można się cieszyć świeżym powietrzem, bliskością jeziora i doskonałymi widokami.</p>
        </div>
        <div className='col animate__animated animate__rotateInDownRight'>
          <Gallery number={numbers}/>
        </div>
        <GuestsAmenities/>
      </div>
    </div>
  )
} 