import './Conditions.scss'

export const Conditions = () =>{

  return(
    <div className='container conditions-module animate__animated animate__fadeInLeft'>
      <div className='row'>
        <div className='col'>
          <h2>Regulamin Domek pod Starym Dębem</h2>
          <h5>§ 1 Postanowienia wstępne</h5>
          <ul>
            <li>Niniejszy Regulamin określa zasady świadczenia usług, odpowiedzialności oraz przebywania na terenie domu letniskowego "Domek pod Starym Dębem", stanowiący integralną 
              część umowy zawieranej przez dokonanie rezerwacji i zapłatę zadatku bądź całej należności za pobyt. Dokonując tych czynności, Gość potwierdza, że zapoznał się z warunkami 
              Regulaminu i zobowiązuje się do ich przestrzegania.</li>
            <li>Potwierdzeniem zawarcia umowy jest jej wypełnienie po przyjeździe Gościa do Obiektu.</li>
            <li>Regulamin jest dostępny na stronie internetowej Obiektu i obowiązuje wszystkich Gości.</li>
          </ul>
          <h5>§ 2 Definicje</h5>
          <ul>
            <li><strong>Obiekt</strong> – Domek letniskowy "Domek pod Starym Dębem", położony w Czarnej Wsi, 6 kilometrów od Rajgrodu, oferujący zakwaterowanie do 7 osób w pełni wyposażonym domku.</li>
            <li><strong>Właściciel</strong> – osoba odpowiedzialna za zarządzanie i reprezentowanie Obiektu, dostępna dla Gości w razie potrzeby.</li>
            <li><strong>Gość</strong> – osoba fizyczna korzystająca z usług Obiektu zgodnie z postanowieniami niniejszego Regulaminu.</li>
            <li><strong>Umowa</strong> – dokument potwierdzający zawarcie umowy pomiędzy Gościem a Obiektem, wypełniany w dniu przyjazdu.</li>
            <li><strong>Kaucja</strong> – kwota pieniężna zabezpieczająca roszczenia Obiektu, w przypadku powstania szkód lub nieuregulowania dodatkowych kosztów pobytu.</li>
          </ul>
          <h5>§ 3 Rezerwacja i meldunek</h5>
          <ul>
            <li>Rezerwacja wymaga wpłaty zadatku w ciągu 7 dni od dokonania rezerwacji na rachunek bankowy podany na stronie internetowej.</li>
            <li>W przypadku rezygnacji z rezerwacji na 14 dni przed planowanym przyjazdem, zadatek jest zwracany w pełnej wysokości.</li>
            <li>Rezygnacja z rezerwacji na mniej niż 14 dni przed przyjazdem skutkuje utratą zadatku.</li>
            <li>Zmiana terminu pobytu po potwierdzeniu rezerwacji nie uprawnia do obniżenia kosztów ani zwrotu opłat.</li>
            <li>Opłata za pobyt oraz kaucja w wysokości 1000 zł są pobierane w dniu przyjazdu. Kaucja jest zwracana w dniu wyjazdu, pod warunkiem, że nie stwierdzono naruszeń Regulaminu.</li>
          </ul>
          <h5>§ 4 Doba hotelowa</h5>
          <ul>
            <li> Doba hotelowa zaczyna się o godzinie 13:00 i kończy o 11:00 następnego dnia.</li>
            <li>Przestrzeganie doby hotelowej jest obowiązkowe.</li>
          </ul>
          <h5>§ 5 Zasady korzystania z obiektu</h5>
          <ul>
            <li>Goście korzystający z zakwaterowania muszą przestrzegać ustalonych cen oraz zasad określonych w Regulaminie.</li>
            <li>Na terenie obiektu zabrania się palenia tytoniu i używania papierosów elektronicznych. Za złamanie tego zakazu naliczana jest dodatkowa opłata 500 zł na likwidację zapachów.</li>
            <li>Goście zobowiązani są do utrzymania czystości i porządku, a także do zgłaszania wszelkich usterkek oraz szkód właścicielom.</li>
            <li>Cisza nocna obowiązuje od 22:00 do 6:00.</li>
          </ul>
          <h5>§ 6 Przetwarzanie danych osobowych</h5>
          <ul>
            <li>Administratorem danych osobowych Gości jest "Domek pod Starym Dębem". Dane zbierane są w celu realizacji umowy, procesu zameldowania, a także dla celów marketingowych, jeśli Gość wyrazi na to zgodę.</li>
          </ul>
          <h5>§ 7 Postanowienia końcowe</h5>
          <ul>
            <li>Właściciel zastrzega sobie prawo do odmowy przyjęcia Gości, którzy naruszają porządek publiczny lub zasady Regulaminu.</li>
            <li>Reklamacje dotyczące usług należy zgłaszać właścicielom niezwłocznie po zaistnieniu przyczyny.</li>
          </ul>
        </div>
      </div>
    </div>
  )

}