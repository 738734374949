import './Gallery.scss'
import React, { Component, useEffect } from 'react';
import { render } from 'react-dom';
import './style.css';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import fjGallery from 'flickr-justified-gallery';


export const Gallery = () =>{

  useEffect(() => {
    fjGallery(document.querySelectorAll('.gallery'), {
      itemSelector: '.gallery__item',
      rowHeight: 180,
      lastRow: 'start',
      gutter: 2,
      rowHeightTolerance: 0.1,
      calculateItemsHeight: false,
    });
  }, []);

  return(
    <div className='gallery-module animate__animated animate__fadeInLeft'>
      <div className='txt-box'>
        <h1>Galeria</h1>
      </div>
        <LightGallery
          plugins={[lgZoom, lgVideo]}
          mode="lg-fade"
          pager={false}
          thumbnail={true}
          galleryId={'nature'}
          autoplayFirstVideo={false}
          elementClassNames={'gallery'}
          mobileSettings={{
            controls: false,
            showCloseIcon: false,
            download: false,
            rotate: false,
          }}
        >
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it2"
            data-tweet-text="lightGallery slide  2"
            className="gallery__item"
            data-src="1.jpg"
          >
            <img
              className="img-responsive"
              src="1.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="2.jpg"
          >
            <img
              className="img-responsive"
              src="2.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2398"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="3.jpg"
          
          >
            <img
              className="img-responsive"
              src="3.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1067"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="4.jpg"
            
          >
            <img
              className="img-responsive"
              src="4.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1067"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="5.jpg"
            
          >
            <img
              className="img-responsive"
              src="5.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1126"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="6.jpg"
           
          >
            <img
              className="img-responsive"
              src="6.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1063"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="7.jpg"
            
          >
            <img
              className="img-responsive"
              src="7.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="8.jpg"
            
          >
            <img
              className="img-responsive"
              src="8.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1144"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="9.jpg"
           
          >
            <img
              className="img-responsive"
              src="9.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1067"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="10.jpg"
            
          >
            <img
              className="img-responsive"
              src="10.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1067"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="11.jpg"
            
          >
            <img
              className="img-responsive"
              src="11.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="12.jpg"
            
          >
            <img
              className="img-responsive"
              src="12.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1067"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="13.jpg"
           
          >
            <img
              className="img-responsive"
              src="13.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="14.jpg"
            
          >
            <img
              className="img-responsive"
              src="14.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2400"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="15.jpg"
            
          >
            <img
              className="img-responsive"
              src="15.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1065"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="16.jpg"
           
          >
            <img
              className="img-responsive"
              src="16.jpg"
            />
          </a>
          <a
            data-lg-size="1600-2134"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="17.jpg"
          >
            <img
              className="img-responsive"
              src="17.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1060"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="18.jpg"
          >
            <img
              className="img-responsive"
              src="18.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1037"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="19.jpg"
          >
            <img
              className="img-responsive"
              src="19.jpg"
            />
          </a>
          <a
            data-lg-size="1600-1037"
            data-pinterest-text="Pin it3"
            data-tweet-text="lightGallery slide  4"
            className="gallery__item"
            data-src="20.jpg"
          >
            <img
              className="img-responsive"
              src="20.jpg"
            />
          </a>
      </LightGallery>
    </div>
  );
};

const HeaderComponent: React.FC = () => (
  <div className="header">
    <h1 className="header__title">lightGallery - Thumbnails</h1>
    <p className="header__description">
      lightGallery is a feature-rich, modular JavaScript gallery plugin for
      building beautiful image and video galleries for the web and the mobile
    </p>
    <a
      className="header__button"
      href="https://github.com/sachinchoolur/lightGallery"
      target="_blank"
    >
      View on GitHub
    </a>
  </div>
  )
