import './Footer.scss'
import { NavLink } from 'react-router-dom'

export const Footer = () =>{

  return(
    <div className='container-fluid footer-module'>
        <div className='row nav-row'>
          <div className='col'>
            <NavLink to={'/'}>
              <p>Strona główna</p>
            </NavLink>  
          </div>  
          <div className='col'>
            <NavLink to={'/dom'}>
              <p>Opis domu</p>
            </NavLink>
          </div>  
          <div className='col'>
            <NavLink to={'/galeria'}>
              <p>Galeria</p>
            </NavLink>
          </div>
          <div className='col'>
            <NavLink to={'/cennik'}> 
              <p>Cennik</p>
          </NavLink>   
          </div>  
          <div className='col'>
            <NavLink to ={'/atrakcje'}>
              <p>Atrakcje</p>
            </NavLink>
          </div>  
          <div className='col'>
            <NavLink to={'/kontakt'}>
              <p>Kontakt</p>
            </NavLink>
          </div>
          <div className='col'>
            <NavLink to={'/regulamin'}>
              <p>Regulamin</p>
            </NavLink>
          </div>
        </div>
        <div className='row txt-row'>
          <div className='col'>
            <p>Zaprojektowane i wykonane przez:<b> Adam Mocarski</b></p>
            <a href='https://www.mocarski-a.pl/'><i class="fa-regular fa-address-card"></i></a>
          </div>
        </div>
    </div>
  )
}