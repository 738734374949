import './ContactData.scss'


export const ContactData = () =>{

  const info = [
    {
      ifont: 'fa-solid fa-location-dot',
      hTxt: 'Adres',
      oneTxt: 'Czarna Wieś 6A',
      twoTxt: 'Rajgród gmina 19-206'
    },
    {
      ifont: 'fa-solid fa-tty',
      hTxt: 'Telefon',
      oneTxt: '608 623 377',
    },
    {
      ifont: "fa-solid fa-at",
      hTxt: 'E-mail',
      oneTxt: 'podstarymdebemczarnawies@gmail.com',
    }
  ]

  return(
    <div className='container'>
      <div className='row  contact-module grid gap-4'>
        {
        info.map((inf, index)=>(
        <div key={index} className='col col-12 col-sm-16 col-lg col-md info-box animate__animated animate__backInUp'>
          <div  className='ifont'>
            <i class={inf.ifont}/>
          </div>
          <div className='txt-box'>
            <h1>{inf.hTxt}</h1>
            <p>{inf.oneTxt}</p>
            {inf.twoTxt && <p>{inf.twoTxt }</p>}
          </div>
        </div>
        ))}
        <iframe  className="animate__animated animate__backInUp" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14567.722449050338!2d22.643184305731612!3d53.70935497223606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e04ac937e3f8b7%3A0xd43b1e1a29fa6c14!2sCzarna%20Wie%C5%9B%206A%2C%2019-206%20Czarna%20Wie%C5%9B!5e0!3m2!1spl!2spl!4v1713788157979!5m2!1spl!2spl" ></iframe>
      </div>
    </div>
  )
}