import './Price.scss'

export const Price = () =>{
  return(
    <div className='container price-module animate__animated animate__fadeInLeft'>
      <div className='row'>
        <div className='col'>
          <h4>Cena</h4>
          <p>500 zł za dobę. Minimalny czas rezerwacji to dwie doby. Przy dłuższych rezerwacjach możliwość negocjacji ceny.</p>
        </div>  
      </div>
      <div className='row'>
          <div className='col'>
            <p>Zameldowanie od godz. 13:00- 22:00</p>
            <p>Wymeldowanie do godz. 11:00</p>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <p>Rezerwacje przyjmujemy mailowo i telefonicznie. Warunkiem dokonania rezerwacji jest wpłata zadatku w wysokości 30% ceny pobytu na konto bankowe.</p>
            <p>Na wpłatę czekamy do 3 dni roboczych od przyjęcia rezerwacji. W razie nie dokonania wpłaty w wyżej wymienionym terminie rezerwacja zostaje anulowana.
              Pozostałą część opłaty za pobyt przyjmujemy w dniu przyjazdu, lub na konto bankowe w ostatnim tygodniu przed przyjazdem, tak aby w dniu zameldowania, kwota była już zaksięgowana.</p>
              <p><b>Szczegółowy regulamin korzystania z obiektu zamieszczony jest w zakładce REGULAMIN.</b></p>
              <p><b>W dniu przyjazdu pobieramy kaucję w wysokości 500 zł. ( kaucja zwrotna w dniu wyjazdu) na poczet ewentualnych zniszczeń.</b></p>
          </div>
        </div>
    </div>
  )
}