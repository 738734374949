import './MainImg.scss'
import 'animate.css';



export const MainImg = () =>{

  return(
    <div className='container-fluid main-img-module'>
      <div className='main-box'>
        <div className='row img-box'>
          <img src='/tlo.jpg'/>
        </div>  
        <div className='logo-box animate__animated animate__zoomIn'>
          <img src='/logo.png'/>
        </div>
      </div>
    </div>
  )
}