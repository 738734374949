import './App.css';
import { NavBar } from './Modules/NavBar/NavBar'
import { MainImg } from './Modules/MainImg/MainImg'
import {BrowserRouter, Route, Routes } from 'react-router-dom'
import { Main } from './Pages/Main/Main'
import { House } from './Pages/House/House'
import { Gallery } from './Pages/Galery/Gallery'
import { Price } from './Pages/Price/Price'
import { Atractions } from './Pages/Atractions/Atractions'
import { Contact } from './Pages/Contact/Contact';
import { Conditions } from './Pages/Conditions/Conditions'
import { ContactData } from './Modules/ContactData/ContactData'
import { Footer } from './Modules/Footer/Footer'
import { NavbarMobileView} from './Modules/NavbarMobileView/NavbarMobileView'

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar/>
        <NavbarMobileView/>
        <MainImg/>
        <Routes>
          <Route path='/' element={<Main/>}/>
          <Route path='/dom' element={<House/>}/>
          <Route path='/galeria'element={<Gallery/>}/>
          <Route path='/cennik' element={<Price/>}/>
          <Route path='/atrakcje' element={<Atractions/>}/>
          <Route path='/kontakt' element={<Contact/>}/>
          <Route path='/regulamin' element={<Conditions/>}/>
        </Routes>
        <ContactData/>
        <Footer/>
      </div>
    </BrowserRouter>
  );
}

export default App;
