import './Atractions.scss'

export const Atractions  = () => {

  return(
    <div className='container atractions-module animate__animated animate__fadeInLeft'>
      <div className='row'>
        <div className='col'>
          <h2>Odkryj Czarną Wieś – Twoja ucieczka w serce natury</h2>
          <p>Zapraszamy do spędzenia niezapomnianych chwil w malowniczej Czarnej Wsi, położonej na pograniczu Mazur i Podlasia. Nasza miejscowość, z 
            liczbą 168 mieszkańców, oferuje ciszę i spokój nad brzegiem krystalicznie czystego Jeziora Rajgrodzkiego, które jest jednym z najczystszych w Polsce. Czarna Wieś to
            idealne miejsce dla miłośników natury i aktywnego wypoczynku dzięki licznie rozwiniętej sieci ścieżek pieszych i rowerowych.</p>
          <h3>Lokalizacja i dostępność</h3>
          <p>Czarna Wieś znajduje się zaledwie 4 km od Rajgrodu, z którym łączy nas malownicza ścieżka rowerowa. W pobliżu gminy znajdują się także inne atrakcyjne miejscowości:
             Grajewo (16 km), Ełk (29 km) oraz Augustów (29 km).</p>
          <h3>Nasz Domek Letniskowy</h3>
          <p>Oferujemy przytulny domek letniskowy z siedmioma miejscami noclegowymi, w pełni wyposażonym aneksem kuchennym oraz dostępem do rozmaitych form rekreacji. Na działce znajduje 
            się miejsce na ognisko, grill, plac zabaw dla dzieci, trampolina, stół do ping-ponga i boisko do siatkówki. Dla miłośników wodnych przygód przygotowaliśmy kajaki, łódź z wiosłami 
            oraz rower wodny. Nieopodal domku znajduje się piaszczysta plaża z pomostem, co gwarantuje doskonałe warunki do kąpieli i relaksu przy wodzie.</p>
          <h3>Atrakcje wokół Czarnej Wsi</h3>
          <p>W okolicy Czarnej Wsi znajdują się ośrodki wypoczynkowe Kormoran i Rybitwa, gdzie można wypożyczyć sprzęt wodny i cieszyć się spokojem nad wodą. Region charakteryzuje się bogactwem naturalnym,
             co sprzyja pieszym i rowerowym wycieczkom. Biebrzański Park Narodowy, znajdujący się w zasięgu krótkiej przejażdżki rowerowej, oferuje unikalne możliwości obserwacji dzikiej przyrody i ptactwa wodnego.</p>
        </div>
      </div>
    </div>

  )
}

