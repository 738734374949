import { Gallery } from '../../Modules/Gallery/Galerry'
import './House.scss'


export const House = ( ) =>{

  return(
    <div className='container house-module'>
      <div className='row'>
        <div className='col-12 col-sm-6 col-md-7 col-lg-8 house-box animate__animated  animate__fadeInRight'>
          <h2>Odkryj Raj w Sercu Natury – Luksusowy Domek Nad Jeziorem Rajgrodzkim</h2>
          <p>Zapraszamy do naszego wyjątkowego domku letniskowego, który stanowi idealne 
            miejsce ucieczki od codziennego zgiełku, oferując oazę spokoju i relaksu na granicy malowniczych Mazur 
            i Podlasia. Położony w cichej i spokojnej Czarnej Wsi, zaledwie kilka kroków od krystalicznie czystego 
            Jeziore Rajgrodzkiego, nasz domek jest prawdziwym rajem dla miłośników natury i aktywnego wypoczynku.</p>
          <h3>Doskonała lokalizacja:</h3>
          <p>Bliskość dużego lasu oraz Biebrzańskiego Parku Narodowego otwiera przed Tobą niezliczone możliwości na piesze i rowerowe wyprawy.</p>
          <p>Szybki dostęp do piaszczystej plaży z pomostem i możliwością wypożyczenia sprzętu wodnego.</p>
          <h3>Komfortowe i stylowe wnętrze</h3>
          <p>Nasz domek zapewnia komfortowe warunki dla 7 osób, dzięki dwóm przytulnym sypialniom z łóżkami dwuosobowymi oraz dodatkowym łóżkiem jednoosobowym.</p>
          <p>Salon z rozkładanym narożnikiem to idealne miejsce na wieczorne relaksy z ulubionym filmem lub książką.</p>
          <p>Nowoczesny aneks kuchenny jest w pełni wyposażony w najnowsze sprzęty, co sprawia, że przygotowanie posiłków staje się prawdziwą przyjemnością.</p>
          <h3>Atrakcje na miejscu:</h3>
          <p>Przestronna działka zapewnia mnóstwo miejsca do rekreacji – od placu zabaw dla dzieci, przez trampolinę, aż po boisko do siatkówki.</p>
          <p>organizuj wieczór przy ognisku lub skorzystaj z naszego grilla na świeżym powietrzu.</p>
          <p>Dostępne sprzęty pływające takie jak kajaki, łódka z wiosłami, czy rower wodny gwarantują doskonałą zabawę na wodzie.</p>
          <h3>Pełna wygoda i dostępność:</h3>
          <p>Dostępne udogodnienia jak telewizor, pralka czy kompletny zestaw pościeli zapewniają wszystko, co potrzebne do komfortowego pobytu.</p>
          <p>Cena 500 zł za dobę z możliwością negocjacji przy dłuższych pobytach oraz minimalny czas rezerwacji wynoszący dwie doby.</p>
          <h5>Oferujemy nie tylko piękne krajobrazy i ciszę, ale również luksus i wygodę. Czy można sobie wyobrazić lepsze miejsce na odpoczynek? Nie czekaj, zarezerwuj 
            swój pobyt już dzisiaj i zanurz się w luksusie otoczonym naturą!</h5>
        </div>

        <div className='col-12 col-sm-6 col-md-5 col-lg-4 summary-box animate__animated animate__fadeInLeft'>
          <ul>
            <li>Kompletnie wyposażony aneks kuchenny (kuchenka gazowa, piekarnik, mikrofalówka, lodówka, zmywarka, czajnik, toster, kompletny zestaw naczyń)</li>
            <li>Przytulne sypialnie z widokiem na jezioro: dwie sypialnie z łóżkami dwuosobowymi, w tym jedna z dodatkowym łóżkiem jednoosobowym</li>
            <li>Bezpośredni dostęp do jeziora i plaży</li>
            <li>Ognisko i grill na zewnątrz</li>
            <li>Możliwość korzystania z sprzętu wodnego: kajaki, łódki</li>
            <li>Salon z komfortowym narożnikiem rozkładanym na dwie osoby</li>
            <li>Łazienka z pralką, suszarką do włosów</li>
            <li>Telewizor oraz komplet pościeli dla każdego miejsca noclegowego</li>
          </ul>
          <Gallery number ={[1,16]} />
        </div>
      </div>
    </div>
  )
}